
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import Input from '../components/form/Input'
import Footer from '../components/Footer'
import Dica from '../assets/rostoRegra.png'
import Switch from "react-switch";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Checked from '../assets/checked.png'
import axios from 'axios';
import WebcamComponent from './WebcamComponent';
import QrReader from 'react-qr-reader'
import './QrCode.css'
import Lottie from 'react-lottie';
import animationData from '../components/animations/qrload.json';
import animationDataQR from '../components/animations/qr.json';
import GPS from '../components/gps'
import Face from './Face';
import SemAccesso from './SemAccesso';
import DetalheConvite from './DetalheConvite'
import ButtonAcionamento from '../components/Input'
import Preload from './PreLoad'
import Success from '../components/alerta/success'
import Erro from '../components/alerta/erro'
import FaceAlert from '../components/alerta/face'
import TelaInformacao from './informacao'
import './CadastroRealizado.css'

import { Navigate } from "react-router-dom";


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataQR,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};



const PreCadastro = props => {



    const [toggle, setToggle] = useState();

    const [morador, setMorador] = useState([]);
    const [form, setForm] = useState({});
    const [tel, setTel] = useState("");

    const [toggleServices, setToggleServices] = useState(false);
    const [toggleCar, setToggleCar] = useState(false);
    const [termo, setTermo] = useState(false);

    const [image, setImage] = useState();

    const [nomeDoCondominio, setNomeDoCondominio] = useState("");
    const [urlLogoEmpresa, setUrlLogoEmpresa] = useState("");
    const [unidadesFilter, setUnidadesFilter] = useState([]);
    const [unidadesFull, setUnidadesFull] = useState([]);
    const [textFilter, setTextFilter] = useState('');
    const [selectUnidade, setSelectUnidade] = useState();
    const [erroTextCPF, setErroTextCPF] = useState('');
    const [formErros, setFormErros] = useState({});
    const [loading, setLoading] = useState(false);
    const [userJaCadastrado, setUserJaCadastrado] = useState(false);
    const [dadosConvite, setDadosConvite] = useState({});
    const [msgLinkExpirado, setMsgLinkExpirado] = useState("");
    const [listButton, setListButton] = useState([]);
    const [gps, setGps] = useState({});
    const [dadosDoConviteAtualizados, setDadosDoConviteAtualizados] = useState(false)

    const isOnGps = useRef(true)
    const isOnQR = useRef(true)


    const handleOnIdle = event => {
        if (toggle !== "erro") setToggle('sessaoExpirada');
    };

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 180,
        onIdle: handleOnIdle,
        debounce: 500
    });


    useEffect(() => {
        if (toggle === "cadastro") setForm({})
    }, [toggle]);

    useEffect(() => {
        const IDFastpass = window.location.href.split("?")[1];
        let tentativas = 0;

        async function carregarConvite(IDFastpass) {

            setToggle('preload');
            // tentativas = tentativas + 1;
            const url = "https://fastpass-br.gear-tecnorise.com:4001/public/invitation/" + IDFastpass

            try {

                const res = await axios.get(url);

                //console.log(res.data)
                if (res && res.data && res.data.status) {

                    if (res.data.resultado.id_visitor) {
                        if (res.data.resultado.isFastpassValidation) {
                            setDadosConvite(res.data.resultado);
                            setToggle('chave');
                            listarAcessos(res.data.resultado);
                        } else {
                            setDadosConvite(res.data.resultado);
                            setToggle('condinfo');
                        }

                    } else {
                        setDadosConvite(res.data.resultado);
                        setToggle('condinfo');
                        // setSelectUnidade({ id: res.data.resultado.id_visitor });
                    };

                } else {
                    setMsgLinkExpirado(res.data.mensagem);
                    setToggle('erro');
                }



            } catch (e) {

                if (tentativas <= 3) {
                    carregarConvite(IDFastpass)
                } else {
                    setToggle('erroCarregamentoDaURL');
                };

            } finally {
                // setLoading(false);
            };
        };
        // carregarConvite();
        //  setToggle('qrcode')
        carregarConvite(IDFastpass);
    }, []);

    useEffect(() => {
        setUnidadesFilter(
            unidadesFull.filter(item => item.name.replace(/\s/g, '').includes(
                textFilter.toUpperCase().replace(/\s/g, ''))
            ));
    }, [textFilter]);


    const handlePage = (unidade) => {
        setSelectUnidade(unidade);
        setToggle('condinfo');
    };

    function qrcodeResult(result) {
        //console.log(result)
    }

    //webcam
    const finalizarFoto = async ({ proxTela, fotoBase64 }) => {
        if (dadosConvite.id_visitor) {
            setToggle("fotoNaoReconhecida");
        } else {
            setToggle(proxTela);
            if (fotoBase64) setImage(fotoBase64);
        };
    };

    const fecharCamera = () => {
        setToggle('condinfo');
    };

    const changeForm = (campo, valor) => {
        setFormErros(prevForm => ({ ...prevForm, [campo]: undefined }));
        setForm(prevForm => ({ ...prevForm, [campo]: valor }));
    };

    const listarAcessos = async (conviteDados) => {
        try {
            const res = await axios.post('https://fastpass-br.gear-tecnorise.com:4001/public/invitation/access', {
                "id_invitation": conviteDados.id_invitation,
                "id_unit": conviteDados.id_unity,
            });

            if (res && res.data && res.data.resultado && res.data.status) {



                if (res.data.resultado.trigger) {
                    setListButton(res.data.resultado.trigger)
                }

                //Giuliano
                //Se o acesso for false, mandar para a nova tela que vou criar.


                if (res.data.resultado.type_acess) {

                    //console.log("listar Acessos", res.data.resultado.type_acess)


                    if (res.data.resultado.type_acess == 'chave' && conviteDados.isGps) {
                        setToggle('realizado_e_confirmado_gps')
                    } else {
                        if (res.data.resultado.type_acess == 'qrcode') {
                            window.localStorage.setItem('@info', JSON.stringify({ conviteDados, listButton: res.data.resultado.trigger }));
                        };
                        if (res.data.resultado.type_acess == 'qrcode') {
                            isOnGps.current = false
                        };
                        setToggle(res.data.resultado.type_acess);
                    }
                }





                // return res.data.resultado
            }

        } catch (e) {
            // console.log({ e })
        } finally {
            // isOnGps.current = true
        }

    };

    const acioinarAcesso = async (d, tela) => {
        try {
            if (tela == 'qrcode') {
                setToggle('preload');
            }

            const res = await axios.post('https://fastpass-br.gear-tecnorise.com:4001/public/invitation/access/trigger', {
                "id_invitation": dadosConvite.id_invitation,
                "id_unit": dadosConvite.id_unity,
                "lat": gps.lat,
                "lng": gps.lng,
                "type_acess": tela,
                ...d
            });


            if (tela == 'qrcode') {
                if (res.data.status) {
                    setToggle('alert_success');
                    setTimeout(() => {
                        setToggle(tela);
                    }, 2000)
                } else {
                    setToggle('alert_erro');
                    setTimeout(() => {
                        setToggle(tela);
                    }, 2000)
                }
            }

            // return res.data.resultado


        } catch (e) {
            if (tela == 'qrcode') {
                setToggle('alert_erro');
                setTimeout(() => {
                    setToggle(tela);
                }, 2000)
            }
            // console.log({ e })
        }

    };

    const saveData = async e => {

        e.preventDefault();
        let erros = {};

        if (!termo) erros.termo = true;
        if (!form.nome || form.nome.length <= 3) erros.nome = true;
        if (!tel || tel.length < 8) erros.tel = true;

        if (toggleServices) {
            if (!form.empresa || form.empresa.length <= 2) erros.empresa = true;
            if (!form.funcao || form.funcao.length <= 2) erros.funcao = true;
        };

        if (toggleCar) {
            if (!form.marca || form.marca.length <= 2) erros.marca = true;
            if (!form.modelo || form.modelo.length <= 2) erros.modelo = true;
            if (!form.placa || form.placa.length <= 2) erros.placa = true;
        };

        if (Object.keys(erros).length > 0) {
            setFormErros(erros)
            return
        };

        const newMorador =
        {
            "id_unit": dadosConvite.id_unity,
            "name": form.nome,
            "base64_photo": image.replace("data:image/jpeg;base64,", "")
        };

        if (tel && tel.slice(0, 2) == "55") {
            newMorador.cell_number = tel.slice(2);
        } else {
            newMorador.cell_number = tel;
        };

        if (toggleServices) {
            newMorador.company = form.empresa
            newMorador.occupation = form.funcao
        };

        if (toggleCar) {
            newMorador.vehicle = {
                "vehicle_type": form.tipoVeiculo ? String(form.tipoVeiculo) : "0",
                "vehicle_brand": form.marca,
                "model": form.modelo,
                "license_plate": form.placa,
                "color": ""
            };
        };



        try {
            // setLoading(true);
            setToggle('preload');
            if (userJaCadastrado) {
                newMorador.id_visitor = userJaCadastrado;
                newMorador.id_invitation = dadosConvite.id_invitation;
                const res = await axios.put('https://fastpass-br.gear-tecnorise.com:4001/public/visitor/invitation', newMorador);
                if (res.data.status) {
                    setToggle('realizado_e_confirmado');

                } else {
                    setToggle('alert_erro');
                }
            } else {
                newMorador.cpf = form.cpf;
                newMorador.id_invitation = dadosConvite.id_invitation;
                const res = await axios.post('https://fastpass-br.gear-tecnorise.com:4001/public/visitor/invitation', newMorador);
                if (res.data.status) {
                    setToggle('realizado_e_confirmado');
                } else {
                    setToggle('alert_erro');
                }
            };

        } catch (e) {
            setToggle('alert_erro');

        } finally {
            // setLoading(false);
        }

        setMorador(prevMorador => [...prevMorador, newMorador])

    };

    const changeSwitch = () => {
        if (!termo === true) {
            changeForm('termos', 'aceitos')
        }
        setTermo(prevState => !prevState)
    };

    async function validarDocumento() {
        const consultarCPF = async (cpf, id_unit) => {
            try {
                //setLoading(true);
                const res = await axios.post('https://fastpass-br.gear-tecnorise.com:4001/public/visitor/cpf', {
                    cpf: cpf,
                    id_unit
                })

                if (res.data && res.data.status) {
                    setForm(prev => ({ ...prev, nome: res.data.resultado.name, }))
                    setTel(res.data.resultado.cell_number.length === 11 ? '55' + res.data.resultado.cell_number : res.data.resultado.cell_number)
                    setUserJaCadastrado(res.data.resultado.id);
                    return true;
                } else {
                    //setUserJaCadastrado(false);
                    // setForm({})

                    return false;
                };

            } catch (e) {
                //setUserJaCadastrado(false);
                return false;
            } finally {
                //setLoading(false);
            };
        };

        if (form.cpf) {

            const resConsultaCPF = await consultarCPF(form.cpf, dadosConvite.id_unity);
            if (resConsultaCPF) {
                setToggle('newCadastro');
            } else {
                setToggle('newCadastro');
            };
        } else {
            setErroTextCPF('Informe um Documento válido.');
        };

    };



    const fotoEncontrada = async ({ nome, id, fotoBase64, cpf }) => {
        if (dadosConvite.id_visitor) {
            setForm(prev => ({ ...prev, nome, cpf }));
            setUserJaCadastrado(id);
            setToggle('alert_face');
            setTimeout(() => {
                listarAcessos(dadosConvite)
            }, 500)
            if (fotoBase64) setImage(fotoBase64);
        } else {
            setForm(prev => ({ ...prev, nome, cpf }));
            setUserJaCadastrado(id);
            setToggle('newCadastro');
            if (fotoBase64) setImage(fotoBase64);
        };
    };

    const titleCase = str => `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`


    const onGps = (e) => {
        if (e) {
            if (isOnGps.current) {
                isOnGps.current = false
                setGps(e)
                setToggle('chave');
            }
        }
    }

    const onQrCode = (qr) => {
        if (isOnQR.current) {
            isOnQR.current = false
            const button = listButton.find(b => b.qr == qr.split("#")[0])
            if (button) {
                acioinarAcesso({ "qr_code": qr, "id_trigger": button.id_trigger }, toggle)
            }
            setTimeout(() => {
                isOnQR.current = true
            }, 3000)
        }


    }

    const onOpenButton = (button) => {
        acioinarAcesso({ "id_trigger": button.id_trigger }, toggle)
    }

    if (toggle === 'erro') {
        return (
            <Erro>
                <div >
                    <span>
                        <b>
                            {msgLinkExpirado ? msgLinkExpirado : 'Recarregue a página'}
                        </b>
                    </span>
                </div>
            </Erro>
        )
    };


    //console.log({ dadosConvite })

    return (
        <div>

            {loading &&
                <div className="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            }

            {toggle === 'condinfoExpirado' && (
                <div className="cond">

                    <header className="headerCond">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: "#dc3545",
                                color: "white",
                                borderRadius: "60px 60px 60px 60px",
                                fontSize: "50px",
                                width: "90px",
                                height: "90px",
                                marginTop: "90px",
                            }}
                        >
                            X
                        </div>
                        <p
                            style={{
                                marginTop: 34,
                                fontSize: 18
                            }}
                        >
                            {msgLinkExpirado}
                        </p>
                        <p
                            style={{
                                margin: 46,
                                marginTop: 14,
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            Entre em contato com o anfitrião para receber um novo convite.
                        </p>
                    </header>

                    <Footer />
                </div>
            )}

            {toggle === 'sessaoExpirada' && (
                <div className="cond">

                    <header className="headerCond">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: "#dc3545",
                                color: "white",
                                borderRadius: "60px 60px 60px 60px",
                                fontSize: "50px",
                                width: "90px",
                                height: "90px",
                                marginTop: "90px",
                            }}
                        >
                            X
                        </div>
                        <p
                            style={{
                                marginTop: 34,
                                fontSize: 18
                            }}
                        >
                            {msgLinkExpirado}
                        </p>
                        <p
                            style={{
                                margin: 46,
                                marginTop: 14,
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            Sua sessão expirou. recarregue a página
                        </p>
                    </header>

                    <Footer />
                </div>
            )}

            {toggle === 'fotoNaoReconhecida' && (
                <div className="cond">

                    <header className="headerCond">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: "#dc3545",
                                color: "white",
                                borderRadius: "60px 60px 60px 60px",
                                fontSize: "50px",
                                width: "90px",
                                height: "90px",
                                marginTop: "90px",
                            }}
                        >
                            X
                        </div>
                        <p
                            style={{
                                marginTop: 34,
                                fontSize: 18
                            }}
                        >
                            Foto não reconhecida
                        </p>
                        <p
                            style={{
                                margin: 46,
                                marginTop: 14,
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            Entre em contato com o anfitrião para receber um novo convite.
                        </p>
                    </header>

                    <Footer />
                </div>
            )}

            {/* {toggle === 'condinfo' && (

                <TelaInformacao info={dadosConvite} onClick={() => setToggle('cadastro')} />

            )} */}

            {/* {toggle === 'condinfo' && (
                dadosConvite.id_visitor !== null && dadosConvite.isFastpassValidation
                    ? (
                        setToggle('chave')
                    ) : (
                        <TelaInformacao info={dadosConvite} onClick={() => setToggle('cadastro')} />
                    )
            )} */}


            {toggle === 'condinfo' && (

                <TelaInformacao info={dadosConvite} onClick={() => setToggle('cadastro')} />

            )}

            {toggle === 'condinfoSemVinculo' && (
                <div className="cond" style={{ marginBottom: 100 }} >


                    <div className='info' style={{ maxWidth: 340, }}>
                        <header
                            style={{
                                marginTop: 80,
                                marginBottom: 30,
                                textAlign: 'center'
                            }}
                        >
                            <p>VOCÊ TEM UM CONVITE</p>
                        </header>
                        <p>
                            Para prosseguir, é necessário fazer o seu cadastro.
                            Vamos precisar coletar a sua foto para identificação,
                            pelo que você tem ciência de que a sua biometria será
                            utilizada para finalidade especifica de acesso ao {dadosConvite.name_place}.
                        </p>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <button className='button' onClick={() => setToggle('cadastro')}>Avançar</button>
                        </div>
                    </div>
                    <div className="dicas">
                        <p>Na hora da foto, veja as dicas:</p>
                        <img src={Dica} alt='dica' style={{ marginTop: 20 }} />
                    </div>

                    <Footer />
                </div>
            )}

            {toggle === 'cadastro' && (
                <WebcamComponent
                    finalizarFoto={finalizarFoto}
                    fecharCamera={fecharCamera}
                    fotoEncontrada={fotoEncontrada}
                    dadosConvite={dadosConvite}
                />
            )}

            {toggle === 'qrcode' && (
                <div className="main">
                    <div style={{ width: '100%' }}>
                        {dadosConvite && !dadosConvite.isAccess ?
                            <Navigate to="/q" state={{ dadosConvite, listButton }} replace={true} />
                            :
                            <>
                                <SemAccesso dadosConvite={dadosConvite} />
                                <DetalheConvite dadosConvite={dadosConvite} />
                            </>
                        }
                    </div>
                </div>


                // <div className="main">

                //     <div style={{ position: 'absolute', top: '10px', width: '300px', height: '300px', overflow: 'hidden' }} className="qrimage">
                //         <QrReader
                //             //onResult={qrcodeResult}
                //             showViewFinder={false}
                //             style={{ width: '100%', borderRadius: '10px' }}
                //             constraints={{
                //                 facingMode: 'environment'
                //             }}
                //             onResult={(result, error) => {

                //             }}
                //             onScan={(result, error) => {
                //                 if (result) onQrCode(result)
                //             }}
                //             onError={(result, error) => {
                //                 // console.log({ result, error })
                //             }}
                //         />
                //     </div>
                //     {/* <div style={{position:'relative', top:'30px', color:'white'}}>Escaneie o QR Code</div> */}
                //     <div style={{ position: 'relative', top: '0px', width: '70%' }}>
                //         <Lottie
                //             options={defaultOptions}
                //             isStopped={false}
                //             isPaused={false}
                //             style={{ margin: 0 }} />
                //     </div>

                //     <div className='qrinfo'>
                //         <div style={{ margin: '15px 10px 64px' }}>
                //             <p style={{ margin: '20px', marginTop: '40px', }}>INSTRUÇÃO DE USO</p>
                //             <div className="instrucao">
                //                 <div className="loading" >
                //                     <Lottie
                //                         options={{
                //                             loop: true,
                //                             autoplay: true,
                //                             animationData: animationData,
                //                             rendererSettings: {
                //                                 preserveAspectRatio: 'xMidYMid slice'
                //                             }
                //                         }}
                //                         height={'150px'}
                //                         width={'150px'}

                //                     />
                //                 </div>
                //                 <div className="info" >No condomínio, localize o QR Code e aponte a câmera para ele</div>
                //             </div>
                //         </div>

                //         <DetalheConvite dadosConvite={dadosConvite} />

                //     </div>
                // </div>
            )}

            {toggle === 'chave' && (
                <div className="main">
                    <div style={{ width: '100%' }}>
                        {dadosConvite && !dadosConvite.isAccess ?
                            listButton.map(b => (
                                <ButtonAcionamento key={b.id_trigger} button={b} onOpenButton={() => { onOpenButton(b) }} />
                            ))
                            :
                            <SemAccesso dadosConvite={dadosConvite} />
                        }
                        <DetalheConvite dadosConvite={dadosConvite} />
                    </div>
                </div>
            )}

            {toggle === 'face' && (
                <div className="main">
                    {dadosConvite && !dadosConvite.isAccess ?
                        <Face />
                        :
                        <SemAccesso dadosConvite={dadosConvite} />
                    }

                    <DetalheConvite dadosConvite={dadosConvite} />
                </div>
            )}

            {toggle === 'sem_acesso' && (
                <div className="main">
                    <SemAccesso dadosConvite={dadosConvite} />
                    <DetalheConvite dadosConvite={dadosConvite} />
                </div>
            )}

            {toggle === 'erroCarregamentoDaURL' && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        textAlign: 'center',
                        marginBottom: 100
                    }}>
                    Erro ao carregar informações. Por favor, tente novamente.
                </div>
            )}

            {toggle === 'verificarCPF' && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'

                    }}
                >
                    <div
                        className="cardFoto"
                        style={{
                            display: "flex",
                            marginTop: 20,
                            backgroundImage: `url(${image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: "center center",
                            marginTop: 50,
                            marginBottom: 40,
                        }}
                    />


                    <div className="input-data">
                        <label>{'Digite seu Documento'}</label>
                        <input
                            value={form.cpf || ""}
                            disabled={toggle === 'verificarCPF' ? false : true}
                            onChange={e => {
                                setErroTextCPF('');
                                changeForm('cpf', e.target.value)
                            }}
                        />
                        {erroTextCPF.length === 0 ?
                            <div
                                style={{
                                    marginTop: 4,
                                    fontSize: 12,
                                    color: 'lightgray',
                                }}

                            >
                                Campo obrigatório
                            </div>
                            :
                            <div
                                style={{
                                    color: 'red',
                                    fontSize: 12,
                                    marginTop: 6,
                                }}
                            >
                                {erroTextCPF}
                            </div>
                        }

                    </div>


                    <div style={{
                        display: 'flex',
                        width: 360,
                        justifyContent: 'space-between'
                    }}>

                        <button
                            style={{ color: 'rgb(164, 164, 164)', border: '1px solid rgb(164, 164, 164)' }}
                            className='button'
                            onClick={() => setToggle('cadastro')}
                        >
                            Tirar outra foto
                        </button>

                        <button
                            className='button'
                            onClick={validarDocumento}
                        >
                            Avançar
                        </button>

                    </div>

                    <Footer />
                </div>
            )}

            {toggle === 'newCadastro' && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <div
                        className="cardFoto"
                        style={{
                            display: "flex",
                            marginTop: 20,
                            backgroundImage: `url(${image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: "center center"
                        }}
                    />

                    <div
                        style={{
                            marginTop: 20
                        }}
                    >

                    </div>

                    <Input
                        type='text'
                        text='Nome e Sobrenome'
                        value={form.nome}
                        onChange={e => changeForm('nome', e.target.value)}
                        style={{ marginTop: 10 }}
                        required={true}
                        erro={formErros.nome}
                        erroText="Digite seu nome e sobrenome"
                    />
                    <Input
                        type='text'
                        text='Documento'
                        value={form.cpf}
                        onChange={e => changeForm('cpf', e.target.value)}
                        disabled={true}
                    />

                    <div
                        className="input-data"
                        style={{
                            margin: 0
                        }}>
                        <label>{'Contato'}</label>
                    </div>

                    <PhoneInput
                        className='phoneInput'
                        country='br'
                        value={tel}
                        onChange={phone => setTel(phone)}
                        inputStyle={{
                            width: '100%',
                            border: 'none',
                            borderBottom: '1px solid #9f9f9f',
                            borderRadius: 0,
                            fontSize: 'larger',
                        }}
                    />

                    {formErros && formErros.tel ?
                        <div
                            className="input-data"
                            style={{
                                color: 'red',
                                fontSize: 12,
                                marginTop: 6,
                            }}
                        >
                            Digite um número válido
                        </div>
                        :
                        <div
                            className="input-data"
                            style={{
                                marginTop: 6,
                                fontSize: 12,
                                color: 'lightgray',
                            }}

                        >
                            Campo obrigatório
                        </div>
                    }

                    {/* <div className='switch'>
                        <label>É um prestador de serviços?</label>
                        <Switch
                            width={50}
                            onChange={() => setToggleServices(!toggleServices)}
                            checked={toggleServices}
                            uncheckedIcon={false}
                            checkedIcon={true}
                        />
                    </div>

                    {toggleServices && (
                        <>
                            <Input
                                type='text'
                                text='Empresa'
                                value={form.empresa}
                                onChange={e => changeForm('empresa', e.target.value)}
                                required={true}
                                erro={formErros.empresa}
                                erroText="Digite o nome da empresa"
                            />
                            <Input
                                type='text'
                                text='Função'
                                value={form.funcao}
                                onChange={e => changeForm('funcao', e.target.value)}
                                required={true}
                                erro={formErros.funcao}
                                erroText="Digite a sua função na empresa"
                            />
                        </>
                    )} */}


                    <div className="termo">
                        <div className='termoHeader'>
                            <div className='termoInfo' >
                                Tenho ciência que os dados pessoais <br /> acima serão tratados para finalidade <br /> específica de acesso ao {dadosConvite.name_place}
                            </div>
                            <Switch
                                width={50}
                                uncheckedIcon={false}
                                checkedIcon={true}
                                checked={termo}
                                onChange={changeSwitch}
                            />
                        </div>

                        <div
                            style={{
                                color: 'red',
                                fontSize: 12,
                                marginTop: 6,
                                width: '100%'
                            }}
                        >
                            É necessário marcar esta opção para prosseguir
                        </div>


                        <button
                            disabled={!termo}
                            className='saveButton'
                            onClick={saveData}
                        >
                            Salvar
                        </button>

                    </div>
                    <Footer />
                </div>
            )}

            {toggle === 'realizado' && (
                <div className='realizado'>
                    <img src={Checked} alt='sucesso' />
                    <span><strong>{morador.map(nome => nome.nome)}</strong><br />Cadastro Realizado com sucesso!</span>
                    <p>Após a liberação por parte de {titleCase(dadosConvite.name_created || "")} você receberá um link para acessar o condomínio.</p>
                </div>
            )}

            {toggle === 'realizado_e_confirmado' && (
                <div className='convite'>
                    <img src={Checked} alt='checado' style={{ width: 120 }} />
                    <div className='text-container'>
                        <div style={{ width: 250 }}><strong>{form.nome || ""}</strong>, o convite enviado por <strong>{titleCase(dadosConvite.name_created)}</strong> está confirmado</div>
                        <div style={{ width: 280 }}>Você tem autorização de acesso ao <strong>{dadosConvite.name_place}</strong> no périodo de {dadosConvite.dat_init} ate {dadosConvite.dat_end}</div>
                        {dadosConvite.flg_validation ?
                            <>
                                <div style={{ width: 280 }}>Para ativar seu convite, você precisa <strong>estar no local</strong>, na <strong>data informada.</strong></div>
                                <div style={{ width: 258 }}>Clique no botão abaixo ou no link recebido no seu <strong>Whatsapp</strong></div>
                            </>
                            :
                            <div style={{ width: 280 }}>Para utilizar seu convite é preciso acessar este link novamente <span className='textDestaque'>no local</span> e na <span className='textDestaque'>data informada</span>, para que as instruções de acesso sejam exibidas.</div>
                        }
                    </div>
                    {dadosConvite.flg_validation &&
                        <button
                            onClick={() => {
                                listarAcessos(dadosConvite)
                                // setToggle('realizado_e_confirmado_gps');
                            }}
                        >
                            Ir para o convite
                        </button>
                    }
                </div>
            )}

            {toggle === 'realizado_e_confirmado_gps' && (
                <div style={{ width: '100%' }}>
                    <GPS dados={dadosConvite} onGps={(e) => onGps(e)} />

                </div>
            )}

            {toggle === 'preload' && (
                <Preload />
            )}

            {toggle === 'alert_success' && (
                <Success />
            )}

            {toggle === 'alert_erro' && (
                <Erro>
                    {/* <div className='realizado'>
                            <span>Recarregue a página</span>
                        </div> */}
                </Erro>
            )}

            {toggle === 'alert_face' && (
                <FaceAlert />
            )}

        </div>
    )
}

export default PreCadastro